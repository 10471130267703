<template>
	<section>
		<p class="question my-1">
			<slot></slot>
		</p>
		<div class="answer my-1">
			<div class="prep mx-3">
				<p class="label" v-if="top">{{ FormMSG(9, 'Prep') }}</p>
				<b-dropdown :text="prepValue" class="m-2 peach-dropdown" variant="peachreport-prep">
					<b-dropdown-item-button value="no" @click="setPoints('prep', 0)">{{ FormMSG(11, 'No') }}</b-dropdown-item-button>
					<b-dropdown-item-button value="yes" @click="setPoints('prep', 1)">{{ FormMSG(12, 'Yes') }}</b-dropdown-item-button>
					<b-dropdown-item-button value="n/a" @click="setPoints('prep', 2)">{{ FormMSG(13, 'N/A') }}</b-dropdown-item-button>
				</b-dropdown>
				<p class="peachreport-prep">{{ availablePoints }}</p>
			</div>
			<div class="wrap mx-3">
				<p class="label" v-if="top">{{ FormMSG(10, 'Wrap') }}</p>
				<b-dropdown :text="wrapValue" class="m-2 peach-dropdown text-capitalize" variant="peachreport-wrap">
					<b-dropdown-item-button value="no" @click="setPoints('wrap', 0)">{{ FormMSG(11, 'No') }}</b-dropdown-item-button>
					<b-dropdown-item-button value="yes" @click="setPoints('wrap', 1)">{{ FormMSG(12, 'Yes') }}</b-dropdown-item-button>
					<b-dropdown-item-button value="n/a" @click="setPoints('wrap', 2)">{{ FormMSG(13, 'N/A') }}</b-dropdown-item-button>
				</b-dropdown>
				<p class="peachreport-wrap">{{ availablePoints }}</p>
			</div>
		</div>
		<div class="notes my-1">
			<p class="label" v-if="top">{{ FormMSG(8, 'Notes') }}</p>
			<b-form-input
				:id="id.toString()"
				:value="notes"
				@update="setPoints('notes', $event)"
				rows="1"
				class="mt-0 peachpearplum-forminput"
				required
				variant="peachreport"
			/>
		</div>
	</section>
</template>
<script>
import { peachStoreStatusMap } from '@/modules/peach-pear-plum/peachCategories.js';
import LanguageMessages from '@/mixins/languageMessages';

export default {
	name: 'FormLine',
	mixins: [LanguageMessages],
	props: {
		questionSubsection: {
			type: String,
			default: ''
		},
		availablePoints: {
			type: Number,
			default: 0
		},
		top: {
			type: Boolean,
			default: false
		},
		prep: {
			type: Number,
			default: 3,
			validator: (value) => [0, 1, 2, 3].includes(value)
		},
		wrap: {
			type: Number,
			default: 3,
			validator: (value) => [0, 1, 2, 3].includes(value)
		},
		notes: {
			type: String,
			default: ''
		},
		id: {
			type: Number,
			default: null
		}
	},
	computed: {
		prepValue() {
			return this.getDropdownValue(this.prep);
		},
		wrapValue() {
			return this.getDropdownValue(this.wrap);
		},
		line() {
			return {
				questionId: this.id,
				questionSubsection: this.questionSubsection,
				prep: this.prep,
				wrap: this.wrap,
				notes: this.notes
			};
		},
		dat() {
			return peachStoreStatusMap;
		}
	},
	methods: {
		getDropdownValue(x = 0) {
			if (x === 3) return '';
			const { id, value } = peachStoreStatusMap[x];
			return this.FormMSG(id, value);
		},
		setPoints(col, v) {
			this.$emit('update:line', { ...this.line, [col]: v });
		}
	}
};
</script>
<style scoped>
section {
	display: flex;
	align-items: center;
	color: var(--text-secondary, rgba(6, 38, 62, 0.84));
	flex-wrap: wrap;
}
p {
	margin-bottom: 0;
}
.prep p.label,
.wrap p.label,
p.label {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	display: block;
	font-weight: 700;
	font-size: 1.2rem;
}
.question {
	flex: 1 1 0%;
	min-width: 300px;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0.18px;
}

.answer {
	display: flex;
	align-items: center;
	justify-content: start;
	width: 400px;
}

.notes {
	width: 100%;
}

@media screen and (min-width: 992px) {
	.notes {
		width: 350px;
	}
}

.wrap,
.prep {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	width: 400px;
}
.wrap p,
.prep p {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	border-radius: 25px;
}

.peach-dropdown {
	width: 100px;
}
</style>
