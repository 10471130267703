<template>
	<Container title="PEACH" v-if="page">
		<template #header>
			{{ FormMSG(page.formId, page.title) }}
		</template>
		<template #buttons>
			<b-button variant="primary" @click="saveReport" class="px-5" :disabled="savingInProgress">
				{{ FormMSG(501, 'Save') }}
			</b-button>
			<component :is="getLucideIcon(ICONS.X.name)" :color="ICONS.X.color" :size="22" />
		</template>
		<main v-if="page && page.questions" class="p-3">
			<div v-for="(question, index) in page.questions" :key="question.formId" class="formline">
				<div v-if="question.questions">
					<div class="pb-3">
						<title-bar>
							{{ FormMSG(question.formId, question.title) }}
						</title-bar>
					</div>
					<form-line
						v-for="(subsection, ind) in question.questions"
						:key="subsection.formId"
						:id="subsection.formId"
						class="mx-3"
						:available-points="subsection.availablePoints"
						:top="ind === 0"
						v-bind="getLine(subsection.formId)"
						:question-subsection="question.sectionId"
						@update:line="updateLine($event, subsection.formId)"
					>
						{{ FormMSG(subsection.formId, subsection.question) }}
					</form-line>
				</div>

				<form-line
					v-else
					:id="question.formId"
					:key="question.formId"
					:available-points="question.availablePoints"
					:top="index === 0"
					v-bind="getLine(question.formId)"
					@update:line="updateLine($event, question.formId)"
				>
					{{ FormMSG(question.formId, question.question) }}
				</form-line>
			</div>
		</main>
	</Container>
</template>
<script>
import TitleBar from '@/modules/peach-pear-plum/components/TitleBar';
import Container from '@/modules/peach-pear-plum/components/Container';
import FormLine from '@/modules/peach-pear-plum/components/peach/FormLine';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { store } from '@/store';
import { categories } from '@/modules/peach-pear-plum/peachCategories.js';
import { getPeachSection, updatePeachSection } from '@/cruds/peachPearPlum.crud';
const baseLine = { prep: 3, wrap: 3, notes: '' };

export default {
	name: 'PeachForm',
	components: {
		Container,
		TitleBar,
		FormLine
	},
	mixins: [languageMessages, globalMixin],
	data() {
		return {
			page: null,
			response: null,
			projectID: 0,
			existingReport: [],
			newReport: [],
			savingInProgress: false,
			saveMessage: null
		};
	},
	created() {
		this.projectID = parseInt(store.projectID(), 10);
	},
	async mounted() {
		this.existingReport = await getPeachSection(this.$route.name);
		this.newReport = [...this.existingReport];
		this.page = categories.filter((x) => x.name === this.$route.name)[0];
	},
	methods: {
		getLine(questionId) {
			const foundIndex = this.newReport.findIndex((x) => x.questionId === questionId);
			return foundIndex !== -1 ? this.newReport[foundIndex] : baseLine;
		},
		updateLine(newLine) {
			let updatingIndex = this.newReport.findIndex((x) => x.questionId === newLine.questionId);
			if (updatingIndex === -1) {
				this.newReport.push(newLine);
			} else {
				this.$set(this.newReport, updatingIndex, newLine);
			}
		},
		async saveReport() {
			this.savingInProgress = true;
			const responseSection = {
				projectId: this.projectID,
				questionSection: this.page.name
			};
			this.savingMessage = this.existingReport.length ? this.FormMSG(503, 'Data updated successfully!') : this.FormMSG(504, 'Data created successfully!');
			await updatePeachSection(this.newReport, responseSection);
			this.createToastForMobile(this.FormMSG(506, 'Success'), this.savingMessage);
			this.savingInProgress = false;
		}
	}
};
</script>
<style lang="scss" scoped>
.formline {
	padding: 15px 10px;
}
</style>
