var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c("p", { staticClass: "question my-1" }, [_vm._t("default")], 2),
    _c("div", { staticClass: "answer my-1" }, [
      _c(
        "div",
        { staticClass: "prep mx-3" },
        [
          _vm.top
            ? _c("p", { staticClass: "label" }, [
                _vm._v(_vm._s(_vm.FormMSG(9, "Prep"))),
              ])
            : _vm._e(),
          _c(
            "b-dropdown",
            {
              staticClass: "m-2 peach-dropdown",
              attrs: { text: _vm.prepValue, variant: "peachreport-prep" },
            },
            [
              _c(
                "b-dropdown-item-button",
                {
                  attrs: { value: "no" },
                  on: {
                    click: function ($event) {
                      return _vm.setPoints("prep", 0)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.FormMSG(11, "No")))]
              ),
              _c(
                "b-dropdown-item-button",
                {
                  attrs: { value: "yes" },
                  on: {
                    click: function ($event) {
                      return _vm.setPoints("prep", 1)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.FormMSG(12, "Yes")))]
              ),
              _c(
                "b-dropdown-item-button",
                {
                  attrs: { value: "n/a" },
                  on: {
                    click: function ($event) {
                      return _vm.setPoints("prep", 2)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.FormMSG(13, "N/A")))]
              ),
            ],
            1
          ),
          _c("p", { staticClass: "peachreport-prep" }, [
            _vm._v(_vm._s(_vm.availablePoints)),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "wrap mx-3" },
        [
          _vm.top
            ? _c("p", { staticClass: "label" }, [
                _vm._v(_vm._s(_vm.FormMSG(10, "Wrap"))),
              ])
            : _vm._e(),
          _c(
            "b-dropdown",
            {
              staticClass: "m-2 peach-dropdown text-capitalize",
              attrs: { text: _vm.wrapValue, variant: "peachreport-wrap" },
            },
            [
              _c(
                "b-dropdown-item-button",
                {
                  attrs: { value: "no" },
                  on: {
                    click: function ($event) {
                      return _vm.setPoints("wrap", 0)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.FormMSG(11, "No")))]
              ),
              _c(
                "b-dropdown-item-button",
                {
                  attrs: { value: "yes" },
                  on: {
                    click: function ($event) {
                      return _vm.setPoints("wrap", 1)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.FormMSG(12, "Yes")))]
              ),
              _c(
                "b-dropdown-item-button",
                {
                  attrs: { value: "n/a" },
                  on: {
                    click: function ($event) {
                      return _vm.setPoints("wrap", 2)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.FormMSG(13, "N/A")))]
              ),
            ],
            1
          ),
          _c("p", { staticClass: "peachreport-wrap" }, [
            _vm._v(_vm._s(_vm.availablePoints)),
          ]),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "notes my-1" },
      [
        _vm.top
          ? _c("p", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.FormMSG(8, "Notes"))),
            ])
          : _vm._e(),
        _c("b-form-input", {
          staticClass: "mt-0 peachpearplum-forminput",
          attrs: {
            id: _vm.id.toString(),
            value: _vm.notes,
            rows: "1",
            required: "",
            variant: "peachreport",
          },
          on: {
            update: function ($event) {
              return _vm.setPoints("notes", $event)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }