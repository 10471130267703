var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.page
    ? _c(
        "Container",
        {
          attrs: { title: "PEACH" },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function () {
                  return [
                    _vm._v(
                      "\n\t\t" +
                        _vm._s(_vm.FormMSG(_vm.page.formId, _vm.page.title)) +
                        "\n\t"
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "buttons",
                fn: function () {
                  return [
                    _c(
                      "b-button",
                      {
                        staticClass: "px-5",
                        attrs: {
                          variant: "primary",
                          disabled: _vm.savingInProgress,
                        },
                        on: { click: _vm.saveReport },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t" +
                            _vm._s(_vm.FormMSG(501, "Save")) +
                            "\n\t\t"
                        ),
                      ]
                    ),
                    _c(_vm.getLucideIcon(_vm.ICONS.X.name), {
                      tag: "component",
                      attrs: { color: _vm.ICONS.X.color, size: 22 },
                    }),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            4217745098
          ),
        },
        [
          _vm.page && _vm.page.questions
            ? _c(
                "main",
                { staticClass: "p-3" },
                _vm._l(_vm.page.questions, function (question, index) {
                  return _c(
                    "div",
                    { key: question.formId, staticClass: "formline" },
                    [
                      question.questions
                        ? _c(
                            "div",
                            [
                              _c(
                                "div",
                                { staticClass: "pb-3" },
                                [
                                  _c("title-bar", [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(
                                            question.formId,
                                            question.title
                                          )
                                        ) +
                                        "\n\t\t\t\t\t"
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _vm._l(
                                question.questions,
                                function (subsection, ind) {
                                  return _c(
                                    "form-line",
                                    _vm._b(
                                      {
                                        key: subsection.formId,
                                        staticClass: "mx-3",
                                        attrs: {
                                          id: subsection.formId,
                                          "available-points":
                                            subsection.availablePoints,
                                          top: ind === 0,
                                          "question-subsection":
                                            question.sectionId,
                                        },
                                        on: {
                                          "update:line": function ($event) {
                                            return _vm.updateLine(
                                              $event,
                                              subsection.formId
                                            )
                                          },
                                        },
                                      },
                                      "form-line",
                                      _vm.getLine(subsection.formId),
                                      false
                                    ),
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              subsection.formId,
                                              subsection.question
                                            )
                                          ) +
                                          "\n\t\t\t\t"
                                      ),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        : _c(
                            "form-line",
                            _vm._b(
                              {
                                key: question.formId,
                                attrs: {
                                  id: question.formId,
                                  "available-points": question.availablePoints,
                                  top: index === 0,
                                },
                                on: {
                                  "update:line": function ($event) {
                                    return _vm.updateLine(
                                      $event,
                                      question.formId
                                    )
                                  },
                                },
                              },
                              "form-line",
                              _vm.getLine(question.formId),
                              false
                            ),
                            [
                              _vm._v(
                                "\n\t\t\t\t" +
                                  _vm._s(
                                    _vm.FormMSG(
                                      question.formId,
                                      question.question
                                    )
                                  ) +
                                  "\n\t\t\t"
                              ),
                            ]
                          ),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }